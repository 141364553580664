
import {
  computed,
  createVNode,
  defineComponent,
  getCurrentInstance,
  h,
  onActivated,
  onMounted,
  reactive,
  ref,
  toRaw,
  toRefs,
  VNode,
} from "vue";
import LogDetail from "@/components/LogDetail/index.vue";
import { getColumns } from "../columns";
import {
  EnumSpeakingType,
  ExaminerArrangementSyncStatusEnum,
  PagedResultOfF2fSpkDeploymentDetail,
  SearchDeploymentF2FActionOperationLogQuery,
  SearchF2FSpkDeploymentQuery,
  F2fSpkDeploymentDetail,
  OnlineSpkDeploymentDetail,
  SpkDeploymentSummary,
} from "@/api-client/client";
import { _Client } from "@/api-client";
import { LogDetailModel } from "@/api-client/modal";
import { message, Modal } from "ant-design-vue";
import { checkAccess } from "@/utils/common";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { useRouter } from "vue-router";
import { DeploymentSearchParams } from "../searchParams";

export default defineComponent({
  components: {
    LogDetail,
    ExclamationCircleOutlined,
  },
  props: {
    searchParams: DeploymentSearchParams,
  },
  setup: function (props, context) {
    const { push } = useRouter();
    const internalInstance = getCurrentInstance();
    const tableLoading = ref<boolean>(false);
    const syncStatusEnum = ExaminerArrangementSyncStatusEnum;
    const syncStatus = {
      SyncNotStart: { name: "Not Start" },
      SyncNeeded: { name: "Sync Needed" },
      Syncing: { name: "Syncing" },
      SyncSuccessful: { name: "Sync Successful" },
      SyncFailed: { name: "Sync Failed" },
    };

    const state = reactive<{
      tableSource: PagedResultOfF2fSpkDeploymentDetail;
      tableSelectedIndexList: string[];
      tableSelectRowList: F2fSpkDeploymentDetail[];
    }>({
      tableSource: PagedResultOfF2fSpkDeploymentDetail.fromJS({ list: [] }),
      tableSelectedIndexList: new Array<string>(),
      tableSelectRowList: new Array<F2fSpkDeploymentDetail>(),
    });

    const columns = computed(() => {
      return getColumns(props.searchParams!.spkType);
    });

    let batchClearBtnLoading: boolean = false;
    let batchSyncToImsBtnLoading: boolean = false;

    const tableActions = () => {
      function handleTableSelectionChange(
        selectedRowKeys: string[],
        selectedRows: any[]
      ) {
        state.tableSelectedIndexList = selectedRowKeys;
        state.tableSelectRowList = toRaw(selectedRows);
        context.emit(
          "tableSelectRowCountChange",
          state.tableSelectedIndexList.length
        );
      }

      function isAllowToSyncIMS(
        spkDeployment: F2fSpkDeploymentDetail
      ): boolean {
        let hasAssignExaminer =
          spkDeployment.amExaminerCount > 0 ||
          spkDeployment.pM1ExaminerCount > 0 ||
          spkDeployment.pM2ExaminerCount > 0;
        return (
          spkDeployment.syncStatus === syncStatusEnum.Syncing ||
          spkDeployment.syncStatus === syncStatusEnum.SyncSuccessful ||
          !hasAssignExaminer
        );
      }

      async function goToAssignPage(spkDeployment: F2fSpkDeploymentDetail) {
        let hasAssignViewPermission = await checkAccess(
          `${internalInstance!.appContext.config.globalProperties.$pageName}:${
            internalInstance!.appContext.config.globalProperties.$actionNames
              .AssignView
          }`,
          spkDeployment.regionId
        );
        if (!hasAssignViewPermission) {
          return;
        }

        let goToRouter = `/examiner/deployment/f2f-assign/${spkDeployment.resourceDeploymentId}`;
        await push(goToRouter);
      }

      async function handelSyncToIMS(spkDeployment: F2fSpkDeploymentDetail) {
        let tableSelectRowList: OnlineSpkDeploymentDetail[] = [];
        tableSelectRowList.push(spkDeployment);
        let modalOkText: string = "Yes";
        let modalCancelText: string = "No";
        let warningTitle: string = "";
        let warningMessage: VNode | null = null;

        // 判断是否有过了考试日的编排数据
        let hasAfterExamDayDeployment = tableSelectRowList.filter(
          (spkDeployment) => {
            return moment(spkDeployment.spkDate,'DD/MM/YYYY').isBefore(moment()) && !moment(spkDeployment.spkDate,'DD/MM/YYYY').isSame(moment(moment(),'DD/MM/YYYY'))
          }
        );
        // 判断是否有未编排数据(AM assigned=0,PM1 assigned=0,PM2 assigned=0)
        let notArrangeExaminerDeployment = tableSelectRowList.filter(
          (spkDeployment) => {
            return (
              spkDeployment.pM2ExaminerCount === 0 &&
              spkDeployment.pM1ExaminerCount === 0 &&
              spkDeployment.amExaminerCount === 0
            );
          }
        );
        if (notArrangeExaminerDeployment.length > 0) {
          warningTitle = "There’s no arrangement to sync.";
          warningMessage = getSessionWarningMessage(
            notArrangeExaminerDeployment
          );
        }
        if (hasAfterExamDayDeployment.length > 0) {
          warningTitle = "Any historical data changed will not be synced.";
          warningMessage = getSessionWarningMessage(hasAfterExamDayDeployment);
        }
        if (
          hasAfterExamDayDeployment.length > 0 ||
          notArrangeExaminerDeployment.length > 0
        ) {
          Modal.warning({
            title: warningTitle,
            content: warningMessage!,
            okText: "Confirm",
            onOk: () => {
              return;
            },
          });
        } else {
          modalOkText = "Yes";
          modalCancelText = "No";
          warningTitle =
            "Are you sure you want to sync these examiner arrangements to downstream system?";
          warningMessage = getSessionWarningMessage(tableSelectRowList);
          let needOverAssignDeployment = tableSelectRowList.filter(
            (spkDeployment) => {
              return (
                spkDeployment.pM2ExaminerCount <
                  spkDeployment.pM2NeedExaminerCount ||
                spkDeployment.pM1ExaminerCount <
                  spkDeployment.pM1NeedExaminerCount ||
                spkDeployment.amExaminerCount <
                  spkDeployment.amNeedExaminerCount
              );
            }
          );
          if (needOverAssignDeployment.length > 0) {
            modalOkText = "Sync";
            modalCancelText = "Cancel";
            warningTitle =
              "The number of examiners assigned doesn’t meet required number.";
            warningMessage = getSessionWarningMessage(needOverAssignDeployment);
            Modal.warning({
              title: warningTitle,
              content: warningMessage,
              okText: "Confirm",
              onOk: () => {
                return;
              },
            });
          } else {
            Modal.confirm({
              title: warningTitle,
              icon: createVNode(ExclamationCircleOutlined),
              content: warningMessage,
              okText: modalOkText,
              cancelText: modalCancelText,
              onOk: async () => {
                // 同步
                let resourceDeploymentIdList = tableSelectRowList.map(
                  (x) => x.resourceDeploymentId
                );
                await _Client.spkDeploymentF2FClient.syncToIms(
                  resourceDeploymentIdList
                );
                await getDeploymentData();
              },
            });
          }
        }
      }

      async function handelClearArrange(spkDeployment: F2fSpkDeploymentDetail) {
        let tableSelectRowList: OnlineSpkDeploymentDetail[] = [];
        tableSelectRowList.push(spkDeployment);
        let warningTitle =
          "Are you sure you want to clear these examiner arrangements in selected session?";
        let warningMessage = getSessionWarningMessage(tableSelectRowList);
        Modal.confirm({
          title: warningTitle,
          icon: createVNode(ExclamationCircleOutlined),
          content: warningMessage,
          okText: "Yes",
          cancelText: "No",
          onOk: async () => {
            let resourceDeploymentIdList = tableSelectRowList.map(
              (x) => x.resourceDeploymentId
            );
            await _Client.spkDeploymentF2FClient.batchDeleteExaminerArrangement(
              resourceDeploymentIdList
            );
            message.success("Delete Success");
            await getDeploymentData();
          },
        });
      }

      function getSessionWarningMessage(
        spkDeploymentDetails: F2fSpkDeploymentDetail[]
      ) {
        return h(
          "div",
          {},
          spkDeploymentDetails.map(
            (spkDeploymentDetail: F2fSpkDeploymentDetail) => {
              return h(
                "p",
                `Session: ${spkDeploymentDetail.spkDate} ${spkDeploymentDetail.centerAbbreviation}`
              );
            }
          )
        );
      }

      const logDetailRef = ref(null);
      let logState = reactive<{
        logSearchParams: SearchDeploymentF2FActionOperationLogQuery;
        logDetail: LogDetailModel;
      }>({
        logSearchParams: new SearchDeploymentF2FActionOperationLogQuery(),
        logDetail: new LogDetailModel(),
      });

      async function showLogDialog(spkDeployment: F2fSpkDeploymentDetail) {
        let hasEditPermission = await checkAccess(
          `${internalInstance!.appContext.config.globalProperties.$pageName}:${
            internalInstance!.appContext.config.globalProperties.$actionNames
              .ViewLog
          }`,
          spkDeployment!.regionId
        );
        if (!hasEditPermission) {
          return;
        }

        logState.logSearchParams = {
          ...logState.logSearchParams,
          pageIndex: 1,
          resourceDeploymentId: spkDeployment.resourceDeploymentId,
        };
        await getLogList();
      }

      async function getLogList() {
        try {
          let res = await _Client.actionOperationLogClient.deploymentF2FQuery(
            logState.logSearchParams
          );
          logState.logDetail = new LogDetailModel(res);
          (logDetailRef.value as any).showLogModal = true;
        } finally {
          (logDetailRef.value as any).showLogModal = true;
        }
      }
      function showSyncMessage(msg: string) {
        Modal.info({
          title: "Sync Message",
          content: msg,
          onOk() {
            console.log("ok");
          },
        });
      }

      return {
        logDetailRef,
        ...toRefs(logState),
        getLogList,
        handleTableSelectionChange,
        isAllowToSyncIMS,
        goToAssignPage,
        handelSyncToIMS,
        handelClearArrange,
        showLogDialog,
        showSyncMessage,
      };
    };

    onMounted(() => {
      getDeploymentData();
    });

    onActivated(() => {
      getDeploymentData();
    });

    async function getDeploymentData() {
      tableLoading.value = true;
      let searchParams = SearchF2FSpkDeploymentQuery.fromJS(props.searchParams);

      let res = await _Client.spkDeploymentF2FClient.search(searchParams);
      state.tableSource = <PagedResultOfF2fSpkDeploymentDetail>(
        res.spkDeploymentDetail
      );
      context.emit(
        "tableRecordCountChange",
        res.spkDeploymentDetail?.recordCount
      );
      context.emit("summaryChange", res.spkDeploymentSummary);
      context.emit("tableSelectRowCountChange", 0);

      state.tableSelectedIndexList = new Array<string>();

      tableLoading.value = false;
    }

    function handelClearArrangeBatch(row: F2fSpkDeploymentDetail[]) {
      let tableSelectRowList: F2fSpkDeploymentDetail[] =
        state.tableSelectRowList;
      // 批量

      batchClearBtnLoading = true;
      context.emit("batchClearBtnLoadingChange", batchClearBtnLoading);
      try {
        let warningTitle =
          "Are you sure you want to clear these examiner arrangements in selected session?";
        let warningMessage = getSessionWarningMessage(tableSelectRowList);
        Modal.confirm({
          title: warningTitle,
          icon: createVNode(ExclamationCircleOutlined),
          content: warningMessage,
          okText: "Yes",
          cancelText: "No",
          onOk: async () => {
            let spkArrangeMentByRoomList: string[] = [];

            // 删除
            let resourceDeploymentIdList = tableSelectRowList.map(
              (x) => x.resourceDeploymentId
            );
            await _Client.spkDeploymentF2FClient.batchDeleteExaminerArrangement(
              resourceDeploymentIdList
            );
            message.success("Delete Success");
            await getDeploymentData();
          },
        });
      } finally {
        batchClearBtnLoading = false;
        context.emit("batchClearBtnLoadingChange", batchClearBtnLoading);
      }
    }

    async function handelSyncToIMSBatch(
      spkDeployment: F2fSpkDeploymentDetail[]
    ) {
      try {
        let tableSelectRowList: F2fSpkDeploymentDetail[] =
          state.tableSelectRowList;
        batchSyncToImsBtnLoading = true;
        context.emit(
          "batchSyncToImsBtnLoadingChange",
          batchSyncToImsBtnLoading
        );
        let modalOkText: string = "Yes";
        let modalCancelText: string = "No";
        let warningTitle: string = "";
        let warningMessage: VNode | null = null;

        // 判断是否有过了考试日的编排数据
        let hasAfterExamDayDeployment = tableSelectRowList.filter(
          (spkDeployment) => {
            return moment(spkDeployment.spkDate,'DD/MM/YYYY').isBefore(moment()) && !moment(spkDeployment.spkDate,'DD/MM/YYYY').isSame(moment(moment(),'DD/MM/YYYY'))
          }
        );
        // 判断是否有未编排数据(AM assigned=0,PM1 assigned=0,PM2 assigned=0)
        let notArrangeExaminerDeployment = tableSelectRowList.filter(
          (spkDeployment) => {
            return (
              spkDeployment.pM2ExaminerCount === 0 &&
              spkDeployment.pM1ExaminerCount === 0 &&
              spkDeployment.amExaminerCount === 0
            );
          }
        );
        if (notArrangeExaminerDeployment.length > 0) {
          warningTitle = "There’s no arrangement to sync.";
          warningMessage = getSessionWarningMessage(
            notArrangeExaminerDeployment
          );
        }
        if (hasAfterExamDayDeployment.length > 0) {
          warningTitle = "Any historical data changed will not be synced.";
          warningMessage = getSessionWarningMessage(hasAfterExamDayDeployment);
        }
        if (
          hasAfterExamDayDeployment.length > 0 ||
          notArrangeExaminerDeployment.length > 0
        ) {
          Modal.warning({
            title: warningTitle,
            content: warningMessage!,
            okText: "Confirm",
            onOk: () => {
              return;
            },
          });
        } else {
          modalOkText = "Yes";
          modalCancelText = "No";
          warningTitle =
            "Are you sure you want to sync these examiner arrangements to downstream system?";
          warningMessage = getSessionWarningMessage(tableSelectRowList);
          let needOverAssignDeployment = tableSelectRowList.filter(
            (spkDeployment) => {
              return (
                spkDeployment.pM2ExaminerCount <
                  spkDeployment.pM2NeedExaminerCount ||
                spkDeployment.pM1ExaminerCount <
                  spkDeployment.pM1NeedExaminerCount ||
                spkDeployment.amExaminerCount <
                  spkDeployment.amNeedExaminerCount
              );
            }
          );
          if (needOverAssignDeployment.length > 0) {
            modalOkText = "Sync";
            modalCancelText = "Cancel";
            warningTitle =
              "The number of examiners assigned doesn’t meet required number.";
            warningMessage = getSessionWarningMessage(needOverAssignDeployment);
            Modal.warning({
              title: warningTitle,
              content: warningMessage,
              okText: "Confirm",
              onOk: () => {
                return;
              },
            });
          } else {
            Modal.confirm({
              title: warningTitle,
              icon: createVNode(ExclamationCircleOutlined),
              content: warningMessage,
              okText: modalOkText,
              cancelText: modalCancelText,
              onOk: async () => {
                // 同步
                let resourceDeploymentIdList = tableSelectRowList.map(
                  (x) => x.resourceDeploymentId
                );
                await _Client.spkDeploymentF2FClient.syncToIms(
                  resourceDeploymentIdList
                );
                await getDeploymentData();
              },
            });
          }
        }
      } finally {
        batchSyncToImsBtnLoading = false;
        context.emit(
          "batchSyncToImsBtnLoadingChange",
          batchSyncToImsBtnLoading
        );
      }
    }

    function getSessionWarningMessage(
      spkDeploymentDetails: F2fSpkDeploymentDetail[]
    ): VNode {
      return h(
        "div",
        {},
        spkDeploymentDetails.map(
          (spkDeploymentDetail: F2fSpkDeploymentDetail) => {
            return h(
              "p",
              `Session: ${spkDeploymentDetail.spkDate} ${spkDeploymentDetail.centerAbbreviation}`
            );
          }
        )
      );
    }

    return {
      tableLoading,
      columns,
      syncStatusEnum,
      syncStatus,
      getDeploymentData,
      ...toRefs(state),
      ...tableActions(),
      handelClearArrangeBatch,
      batchClearBtnLoading,
      handelSyncToIMSBatch,
      batchSyncToImsBtnLoading,
      getSessionWarningMessage,
    };
  },
});
