import { EnumSpeakingType } from '@/api-client/client'

export const getColumns = (spkType: EnumSpeakingType) => {
  let columns = [
    {
      title: 'Spk Day',
      dataIndex: 'spkDate',
      width: 125
    },
    {
      dataIndex: 'am',
      title: 'AM',
      width: 135,
      align: 'center'
    },
    {
      dataIndex: 'pm1',
      title: 'PM1',
      width: 135,
      align: 'center'
    },
    {
      dataIndex: 'pm2',
      title: 'PM2',
      width: 135,
      align: 'center'
    },
    {
      title: 'Status',
      dataIndex: 'syncStatus',
      width: 140
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 270,
    }
  ]
  if (spkType === EnumSpeakingType.VCS || spkType === EnumSpeakingType.ICS) {
    columns.splice(1, 0,
      {
        title: 'Examiner Hub',
        dataIndex: 'examinerHubName',
        width: 160
      } as any,
      {
        title: 'Abbreviation',
        dataIndex: 'examinerHubAbbreviation',
        width: 120
      } as any,
      {
        title: 'Hub City',
        dataIndex: 'hubCity',
        width: 110
      } as any
    )
  }
  if (spkType === EnumSpeakingType.F2F) {
    columns.splice(1, 0,
      {
        title: 'Center Name',
        dataIndex: 'centerName',
        width: 160,
        ellipsis: true,
      } as any,
      {
        title: 'Abbreviation',
        dataIndex: 'centerAbbreviation',
        width: 120
      } as any,
      {
        title: 'Center City',
        dataIndex: 'centerCity',
        width: 110
      } as any
    )
  }
  return columns
}
