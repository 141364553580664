import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import Pagination from "@/components/Pagination/index.vue";
import moment, { Moment } from "moment";
import {
    CityExaminerNumberDetail,
    EnumSpeakingType, ExportExaminerArrangementQuery, FileResponse,
    GetAllRegionRep, SpkDeploymentSummary
} from "@/api-client/client";
import { _Client } from "@/api-client";
import { ExportXlsx } from "@/utils/common";
import { addTimezoneByDbNameForce, ConvertTimeZoneDate } from "@/utils/DateExtensions";
import { DeploymentSearchParams } from "./searchParams";
import { computed } from "vue";
import dayjs, { Dayjs } from 'dayjs';
import OnlineTable from "./online_deployment_table/index.vue";
import F2fTable from "./f2f_deployment_table/index.vue";

export default defineComponent({
    components: {
        Pagination,
        OnlineTable,
        F2fTable
    },
    setup() {
        const defaultSearchParams = <DeploymentSearchParams>{
            startDate: addTimezoneByDbNameForce(moment().format('YYYY-MM-DD'), 'Etc/GMT-0'),
            endDate: addTimezoneByDbNameForce(moment().format('YYYY-MM-DD'), 'Etc/GMT-0'),
            pageIndex: 1,
            pageSize: 10
        };

        const examinerCityTableLoading = ref<boolean>(false);
        const exportExaminerArrangementLoading = ref<boolean>(false)
        const currentComponentTableType = ref<any>(OnlineTable)
        const currentComponentTable = ref<any>(null)
        const batchSyncBtnLoading = ref<boolean>(false)
        const hackValue = ref<[Dayjs, Dayjs]>()
        const dates = ref<[Dayjs, Dayjs]>();
        const state = reactive<{
            regionList: GetAllRegionRep[],
            searchParams: DeploymentSearchParams,
            tableRecordCount: number,
            spkDeploymentSummary: SpkDeploymentSummary,
            searchDateRange: {
                startDate: Moment | null,
                endDate: Moment | null
            },
            tableSelectRowCount: number,
            searchDateParams: [Dayjs, Dayjs] | undefined
        }>({
            regionList: new Array<GetAllRegionRep>(),
            searchParams: <DeploymentSearchParams>{ ...defaultSearchParams, spkType: EnumSpeakingType.VCS },
            tableRecordCount: 0,
            spkDeploymentSummary: new SpkDeploymentSummary(),
            searchDateRange: {
                startDate: null,
                endDate: null
            },
            tableSelectRowCount: 0,
            searchDateParams: [dayjs(), dayjs()]
        })

        let batchClearBtnLoading: boolean = false
        let batchSyncToImsBtnLoading: boolean = false

        onMounted(async () => {
            await getRegionList()
        })

        async function getRegionList() {
            state.regionList = await _Client.regionClient.getAll(true)
        }

        function getExaminerCityTableCellData(row: CityExaminerNumberDetail, speakingDay: Date): number {
            return row.speakingDayDetails!.filter((examinerCityItem: any) => {
                return examinerCityItem.speakingDay.getTime() === speakingDay.getTime()
            })[0].examinerNumber
        }

        function disabledDate(currentDate: Dayjs) {

            if (!dates.value || (dates.value as any).length === 0) {
                return false;
            }
            const tooLate = dates.value[0] && currentDate.diff(dates.value[0], 'days') > 7;
            const tooEarly = dates.value[1] && dates.value[1].diff(currentDate, 'days') > 7;
            return tooEarly || tooLate;
        }
        function calendarChange(rangeDates: [Dayjs, Dayjs]) {
            dates.value = rangeDates;
        }

        function onOpenChange(open: boolean) {
            if (open) {
                dates.value = [] as any;
                hackValue.value = [] as any;
            } else {
                hackValue.value = undefined;
            }
        }

        async function spkDateChange(rangeDates: [Dayjs, Dayjs]) {
            state.searchDateParams = rangeDates
            if (rangeDates === null) {
                [state.searchParams.startDate, state.searchParams.endDate] = [addTimezoneByDbNameForce(moment().format('YYYY-MM-DD'), 'Etc/GMT-0'), addTimezoneByDbNameForce(moment().format('YYYY-MM-DD'), 'Etc/GMT-0')]
            } else {
                [state.searchParams.startDate, state.searchParams.endDate] = [ConvertTimeZoneDate(rangeDates[0].format('YYYY-MM-DD'), 'Etc/GMT-0').toDate(), ConvertTimeZoneDate(rangeDates[1].format('YYYY-MM-DD'), 'Etc/GMT-0').toDate()]
            }
            await changeSearchParams()
        }

        async function changeSearchParams() {
            state.searchParams.pageIndex = 1
            await getDeploymentData()
        }

        async function resetSearchParams() {
            state.searchDateParams = [dayjs(), dayjs()];

            switch (state.searchParams.spkType) {
                case EnumSpeakingType.F2F:
                    state.searchParams = <DeploymentSearchParams>{ officeId: undefined, spkType: EnumSpeakingType.VCS, startDate: addTimezoneByDbNameForce(moment().format('YYYY-MM-DD'), 'Etc/GMT-0'), endDate: addTimezoneByDbNameForce(moment().format('YYYY-MM-DD'), 'Etc/GMT-0'), pageIndex: 1, pageSize: 10 }
                    currentComponentTableType.value = OnlineTable;
                    break
                case EnumSpeakingType.ICS:
                case EnumSpeakingType.VCS:
                    // state.searchParams = <DeploymentSearchParams>{ spkType:EnumSpeakingType.VCS, startDate: addTimezoneByDbNameForce(moment().format('YYYY-MM-DD'), 'Etc/GMT-0'), endDate: addTimezoneByDbNameForce(moment().format('YYYY-MM-DD'), 'Etc/GMT-0')}
                    state.searchParams.officeId = undefined;
                    state.searchParams.startDate = ConvertTimeZoneDate(moment().format('YYYY-MM-DD'), 'Etc/GMT-0').toDate()
                    state.searchParams.endDate = ConvertTimeZoneDate(moment().format('YYYY-MM-DD'), 'Etc/GMT-0').toDate()
                    state.searchParams.pageIndex = 1
                    state.searchParams.pageSize = 10
                    await changeSearchParams()
                    break
            }

        }

        async function spkTypeChangeSearchParams() {
            switch (state.searchParams.spkType) {
                case EnumSpeakingType.F2F:
                    currentComponentTableType.value = F2fTable;
                    break;
                case EnumSpeakingType.VCS:
                    currentComponentTableType.value = OnlineTable;
                    break;
                case EnumSpeakingType.ICS:
                    currentComponentTableType.value = OnlineTable;
                    break;
            }
        }

        async function getDeploymentData() {
            await currentComponentTable.value.getDeploymentData()
        }

        function deploymentSummaryChange(summary: SpkDeploymentSummary) {
            state.spkDeploymentSummary = summary;
        }

        function tableRecordCountChange(tableRecordCount: number) {
            state.tableRecordCount = tableRecordCount;
        }

        async function exportExaminerArrangement() {
            try {
                exportExaminerArrangementLoading.value = true
                let excelStream: FileResponse | null = null
                let params =  ExportExaminerArrangementQuery.fromJS({
                    regionUid: state.searchParams.officeId,
                    startDate: state.searchParams.startDate,
                    endDate: state.searchParams.endDate
                })
                excelStream = await _Client.spkDeploymentOnlineClient.exportExaminerArrangement(params)
                ExportXlsx(excelStream, excelStream!.fileName || 'Examiner Arrangement.xlsx')
            } finally {
                exportExaminerArrangementLoading.value = false
            }
        }

        function tableSelectRowCountChange(selectRowCount: number) {
            state.tableSelectRowCount = selectRowCount;
        }

        async function handelClearArrangeBatch() {
            await currentComponentTable.value.handelClearArrangeBatch()
        }

        async function handelSyncToIMSBatch() {
            await currentComponentTable.value.handelSyncToIMSBatch()
        }

        async function batchClearBtnLoadingChange(batchClearBtnLoadingRef: boolean) {
            batchClearBtnLoading = batchClearBtnLoadingRef
        }

        async function batchSyncToImsBtnLoadingChange(batchSyncToImsBtnLoadingRef: boolean) {
            batchSyncToImsBtnLoading = batchSyncToImsBtnLoadingRef
        }

        const baseCityColumns = computed(() => {
            if (!state.spkDeploymentSummary.header) {
                return [];
            }
            let columns = [{
                title: 'Examiner Base City',
                dataIndex: 'cityName'
            }];
            let spkDayColumns = state.spkDeploymentSummary.header.map((item) => {
                let spkDay = moment(item.toString()).format('DD/MM/YYYY');
                return {
                    title: spkDay,
                    dataIndex: "speakingDayDetails",
                    key: "speakingDayDetails"
                };
            })
            return columns.concat(spkDayColumns)
        })

        return {
            timeMoment: moment,
            speakingTypeEnum: EnumSpeakingType,
            examinerCityTableLoading,
            exportExaminerArrangementLoading,
            currentComponentTableType,
            currentComponentTable,
            ...toRefs(state),
            getExaminerCityTableCellData,
            exportExaminerArrangement,
            disabledDate,
            changeSearchParams,
            resetSearchParams,
            spkTypeChangeSearchParams,
            spkDateChange,
            hackValue,
            onOpenChange,
            calendarChange,
            getDeploymentData,
            deploymentSummaryChange,
            tableRecordCountChange,
            tableSelectRowCountChange,
            batchClearBtnLoading,
            handelClearArrangeBatch,
            batchClearBtnLoadingChange,
            batchSyncToImsBtnLoading,
            batchSyncBtnLoading,
            baseCityColumns,
            handelSyncToIMSBatch,
            batchSyncToImsBtnLoadingChange
        }
    }
});
